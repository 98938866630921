export default [
  {
    path: 'recruit/index',
    component: resolve => require(['./index'], resolve)
  },
  /**
   * 招聘列表
   */
  {
    path: 'recruit/list',
    component: resolve => require(['./List'], resolve)
  },
  /**
   * 新增招聘页面
   */
  {
    path: 'recruit/add',
    component: resolve => require(['./Add'], resolve)
  },
  /**
   * 招聘详情
   */
  {
    path: 'recruit/detail',
    component: resolve => require(['./Detail'], resolve)
  },
  /**
   * 报名记录
   */
  {
    path: 'recruit/recruitEnlist',
    component: resolve => require(['./recruitEnlist'], resolve)
  },
  /**
   * 浏览记录
   */
  {
    path: 'recruit/recruitView',
    component: resolve => require(['./recruitView'], resolve)
  },
  /**
   * 返佣列表
   */
  {
    path: 'recruit/returnList',
    component: resolve => require(['./returnList'], resolve)
  },
  /**
   * 返佣详情
   */
  {
    path: 'recruit/returnDetail',
    component: resolve => require(['./returnDetail'], resolve)
  },
  /**
   * 返佣名单/结算名单
   */
  {
    path: 'recruit/payList',
    component: resolve => require(['./payList'], resolve)
  }
];
