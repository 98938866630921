export default [
  {
    path: 'mybank/info',
    component: resolve => require(['./MybankAccount'], resolve),
    meta: {
      name: '企业账户',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
      // icon: require("/src/assets/user03.svg"),
      // icons: require("/src/assets/user03_show.svg")
    }
  },
  {
    path: 'mybank/bankLine',
    component: resolve => require(['./bankLineSearch'], resolve),
    meta: {
      name: '联行号查询',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  }
];
