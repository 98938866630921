export default [
  {
    path: 'agent/agentManagement',
    name: 'ContentagentManagement',
    component: () => import('./agentManagement.vue'),
    meta: {
      name: '代理管理',
      isTag: true,
      icon: require('/src/assets/agent.svg'),
      icons: require('/src/assets/agent_show.svg')
    }
  },
  {
    path: 'agent/agentReconciliation',
    name: 'ContentagentReconciliation',
    component: () => import('./agentReconciliation.vue'),
    meta: {
      name: '代理端对账',
      isTag: true,
      icon: require('/src/assets/agent01.svg'),
      icons: require('/src/assets/agent01_show.svg')
    }
  },
  {
    path: 'agent/reconciliationDetails',
    name: 'ContentreconciliationDetails',
    component: () => import('./reconciliationDetails.vue')
  },
  {
    path: 'agent/contractingEnterprisesList',
    name: 'ContentcontractingEnterprisesList',
    component: () => import('./contractingEnterprisesList.vue'),
    meta: {
      name: '企业客户管理',
      isTag: true,
      icon: require('/src/assets/user05.svg'),
      icons: require('/src/assets/user05_show.svg')
    }
  },
  {
    path: 'agent/businessPersonnelList',
    name: 'ContentbusinessPersonnelList',
    component: () => import('./businessPersonnelList.vue'),
    meta: {
      name: '业务人员',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/platformBusinessPersonalList',
    name: 'platformBusinessPersonalList',
    component: () => import('./platformBusinessPersonalList'),
    meta: {
      name: '业务人员',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/agentEnterprise',
    name: 'agentEnterprise',
    component: () => import('./agentEnterprise'),
    meta: {
      name: '代理企业',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/platformAgentEnterprise',
    name: 'platformAgentEnterprise',
    component: () => import('./platformAgentEnterprise'),
    meta: {
      name: '代理企业',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/agentStastics',
    name: 'agentStastics',
    component: () => import('./agentStastics'),
    meta: {
      name: '代理业绩统计',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/agentEnterpriseStastics',
    name: 'agentEnterpriseStastics',
    component: () => import('./agentEnterpriseStastics'),
    meta: {
      name: '代理企业业绩统计',
      isTag: true,
      icon: require('/src/assets/user03.svg'),
      icons: require('/src/assets/user03_show.svg')
    }
  },
  {
    path: 'agent/personnelDetails',
    name: 'ContentpersonnelDetails',
    component: () => import('./personnelDetails.vue')
  }
];
