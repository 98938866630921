export default {
  namespaced: true,
  state: () => ({
    nameList: [],
    objList:[]
  }),
  mutations: {
    setNameList(state, data) {
      state.nameList = data;
    },
    setobjList(state, data) {
      state.objList = data;
    }
  },
  actions: {
    setNameList(context, nameList) {
      context.commit('setNameList', nameList);
    },
    setobjList(context, objList) {
      context.commit('setobjList', objList);
    }
  }
};
