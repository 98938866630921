export default [
  {
    path: 'newsManagement/List',
    component: resolve => require(['./index'], resolve)
  },
  {
    path: 'newsManagement/Detail',
    component: resolve => require(['./Detail'], resolve)
  },
  {
    path: 'newsManagement/Add',
    component: resolve => require(['./Add'], resolve)
  },
  {
    path: 'newsManagement/Edit',
    component: resolve => require(['./Edit'], resolve)
  }
];
