import { api } from '/src/api/base';

export const statisticsMakerPayRecoreds = api()(
  'task.statistics.user.total.json'
);
export const taskStatisticsAllUserTotal = api()(
  'task.statistics.all.user.total.json'
);

import moment from 'moment';
import * as math from 'mathjs';
// const timer = 0
export default {
  namespaced: true,
  state: () => ({
    list: [],
    keywords: '',
    pay_platform: '',
    platformList: [],
    dateTime: [
      moment()
        .month(moment().month())
        .startOf('month')
        .format('YYYY-MM-DD') || '',
      moment()
        .month(moment().month())
        .endOf('month')
        .format('YYYY-MM-DD') || ''
    ],
    pageNumber: 1,
    pageSize: 10,
    total: 0,
    makerInfo: null,
    pageSizes: [10, 50, 100, 200, 300, 400, 500],
    selectData: [],
    enterpriseCode: '',
    totalInfo: {
      times: 0,
      totalAmount: 0
    },
    timeName: ''
  }),
  mutations: {
    setSelectData(state, data) {
      state.selectData = data;
    },
    setList(state, data) {
      state.list = data.list;
      state.total = data.total;
    },
    setTotalInfo(state, data) {
      state.totalInfo = data;
    },
    setPageSize(state, data) {
      state.pageSize = data;
    },
    setPageNumber(state, data) {
      state.pageNumber = data;
    },
    setKeywords(state, data) {
      state.keywords = data;
      state.pageNumber = 1;
      state.pageSize = 10;
    },
    setPlatform(state, data) {
      state.pay_platform = data;
      state.pageNumber = 1;
      state.pageSize = 10;
    },
    setPlatformList(state, data) {
      state.platformList = data;
    },
    setEnterpriseCode(state, data) {
      state.enterpriseCode = data;
    },
    setTimeName(state, data) {
      state.timeName = data;
    },
    setMakerInfo(state, data) {
      state.makerInfo = data;
    },
    setClear(state) {
      state.pageNumber = 1;
      state.pageSize = 10;
      state.total = 0;
      state.timeName = '';
      state.dateTime = [
        moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD') || '',
        moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD') || ''
      ];
    },
    setDateTime(state, data) {
      state.dateTime[0] = data[0];
      state.dateTime[1] = data[1];
      state.pageNumber = 1;
      state.pageSize = 10;
    }
  },
  actions: {
    async updateList({ commit, state }) {
      const params = {
        keywords: state.keywords,
        pay_platform: state.pay_platform,
        enterpriseCode: state.enterpriseCode,
        startTime:
          state.dateTime.length !== 0 ? state.dateTime[0] + ' 00:00:00' : '',
        endTime:
          state.dateTime.length !== 0 ? state.dateTime[1] + ' 23:59:59' : '',
        pageSize: state.pageSize,
        pageNumber: state.pageNumber,
        $ignoreRepeat: true
      };
      console.log(state.timeName, state.dateTime, 'state');
      const result = await statisticsMakerPayRecoreds(params);
      const _list = [];
      const reduce = moment(state.dateTime[1]).diff(
        moment(state.dateTime[0]),
        'days'
      );
      console.log(reduce);
      for (let index = 0; index < result.list.length; index++) {
        const it = result.list[index];
        it.personTax = 0;
        const _base = it.amount * 0.1;

        if (
          (state.timeName === '本年' && it.pay_platform === 'm1') ||
          (state.timeName === '自定义' &&
            it.pay_platform === 'm1' &&
            reduce > 30)
        ) {
          if (_base > 24000 && _base <= 30000) {
            it.personTax = math.round(math.evaluate('x*0.05', { x: _base }), 2);
          }
          if (_base > 30000 && _base <= 90000) {
            it.personTax =
              math.round(math.evaluate('x*0.1', { x: _base }), 2) - 1500;
          }
          if (_base > 90000 && _base <= 300000) {
            it.personTax =
              math.round(math.evaluate('x*0.2', { x: _base }), 2) - 10500;
          }
        }
        if (
          (state.timeName === '本月' && it.pay_platform === 'm1') ||
          (state.timeName === '自定义' &&
            it.pay_platform === 'm1' &&
            reduce <= 30)
        ) {
          if (_base > 2000 && _base <= 30000) {
            it.personTax = math.round(math.evaluate('x*0.05', { x: _base }), 2);
          }
        }

        // if (
        //   it.amount > 100000 &&
        //   (it.pay_platform === "m2" || it.pay_platform === "m3")
        // ) {
        //   it.personTax = math.round(
        //     math.evaluate("x*0.01", { x: it.amount - 100000 }),
        //     2
        //   );
        // }
        _list.push(it);
      }
      result.list = _list;
      commit('setList', result);
      // const fn = async () => {
      //   const params = {
      //     keywords: state.keywords,
      //     pay_platform: state.pay_platform,
      //     startTime: state.dateTime.length !== 0 ? (state.dateTime[0] + ' 00:00:00') : '',
      //     endTime: state.dateTime.length !== 0 ? (state.dateTime[1] + ' 23:59:59') : '',
      //     pageSize: state.pageSize,
      //     pageNumber: state.pageNumber
      //   }
      //   console.log(state.pageNumber, 'updateList')
      //   const result = await statisticsMakerPayRecoreds(params)
      //   commit('setList', result)
      // }
      // if (timer === 0) {
      //   fn()
      // }
      // clearTimeout(timer)
      // timer = setTimeout(() => {
      //   if (timer !== 0) {
      //     fn()
      //   }
      // }, 500)
    },
    async getTotalInfo({ commit, state }) {
      const params = {
        keywords: state.keywords,
        pay_platform: state.pay_platform,
        enterpriseCode: state.enterpriseCode,
        startTime:
          state.dateTime.length !== 0 ? state.dateTime[0] + ' 00:00:00' : '',
        endTime:
          state.dateTime.length !== 0 ? state.dateTime[1] + ' 23:59:59' : '',
        $ignoreRepeat: true
      };
      const res = await taskStatisticsAllUserTotal(params);
      commit('setTotalInfo', res);
    },
    async init({ dispatch, state }) {
      if (state.list.length === 0) {
        dispatch('updateList');
        dispatch('getTotalInfo');
      }
    }
  }
};
