import Vue from 'vue';
import Vuex from 'vuex';
import statisticsMaker from './modules/statisticsMaker';
import statisticsEnterprise from './modules/statisticsEnterprise';

import badMaker from './modules/badMaker';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    platformList: [],
    action: '',
    maker: {}
  },
  mutations: {
    setGlobalValue(state, { key, value }) {
      state[key] = value;
    }
  },
  actions: {},
  modules: {
    statisticsMaker,
    statisticsEnterprise,
    badMaker
  }
});
