import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './views/router';
import store from './store';
import './styles.css';
import './filters/common.filters';
import './filters/money.filter';
import moment from 'moment';
import { getPreloadData } from './lib/global';
import * as _ from 'lodash';
import '/public/js/iframe/iframeResizer.min.js';
import echarts from 'echarts';

console.log(new Vue({
  el: 'root',
  data: {
    name:'111'
  }
}));
Vue.prototype.$echarts = echarts;
moment.locale('zh-cn');
// 修改 el-dialog 默认点击遮罩层为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
const popoverClose = () => {
  const list = [];
  const _mounted = ElementUI.Popover.mounted;
  ElementUI.Popover.mounted = function() {
    this.$refs.wrapper.addEventListener('click', event => {
      event.stopPropagation();
      list.forEach(it => {
        it.doClose();
      });
      this.doShow();
      return true;
    });
    list.push(this);
    window._list = list;
    _mounted.call(this);
  };
  window.addEventListener('click', () => {
    list.forEach(it => {
      it.doClose();
    });
  });
};
popoverClose();
Vue.directive('prev', {
  inserted: function(el, binding, val) {
    el.addEventListener('click', () => {
      router.push({
        path: _.get(val, 'data.attrs.path', '/'),
        query: _.get(val, 'data.attrs.query', {})
      });
    });
  }
});
let cacheReg;
Vue.directive('access', {
  inserted: function(el) {
    if (!cacheReg) {
      const access = window.localStorage.getItem('action') || '$null';
      cacheReg = new RegExp(access);
    }
    if (el.attributes['data-ctrl']&&!cacheReg.test(el.attributes['data-ctrl'].nodeValue)) {
      el && el.parentNode && el.parentNode.removeChild(el);
    }
  }
});

Vue.directive('preAccess', {
  inserted: function(el) {
    let reg = new RegExp(getPreloadData('touristInitData'));
    if (!reg.test(el.attributes['data-ctrl'].nodeValue)) {
      el && el.parentNode.removeChild(el);
    }
  }
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// document.body.style.zoom = document.body.clientWidth / 1920;
