import { api } from './base/index';
const dataRightsApi = api('/access');

/**
 * 新增角色
 */
export const dataRightsAdd = dataRightsApi('access.add.json');
/**
 * 角色列表
 */
export const dataRightsList = dataRightsApi('access.list.json');
/**
 * 角色详情
 * */
export const dataRightsDescribe = dataRightsApi('access.describe.json');
/**
 * 角色修改
 * */
export const dataRightsEdit = dataRightsApi('access.edit.json');
/**
 * 角色删除 */
export const dataRightsDel = dataRightsApi('access.del.json');
