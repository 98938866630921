export default [
  {
    path: 'followEnterprise',
    component: resolve => require(['./List.vue'], resolve),
    meta: {
      name: '用户管理',
      isTag: true
    }
  },
  {
    path: 'followEnterprise/list',
    name: 'followEnterpriseList',
    component: () => import('./List.vue'),
    meta: {
      name: '跟进的企业',
      isTag: true,
      icon: require('/src/assets/agent04.svg'),
      icons: require('/src/assets/agent04_show.svg')
    }
  },
  {
    path: 'followEnterprise/detail',
    name: 'followEnterpriseDetail',
    component: () => import('./Detail.vue')
  }
];
