import { api } from '/src/api/base';
export const statisticsEnterprisePayRecoreds = api()(
  'task.statistics.enterprise.total.json'
);
export const describeEnterpriseTotalAmount = api()(
  'task.statistics.platform.total.json'
);

import moment from 'moment';
import * as math from 'mathjs';
export default {
  namespaced: true,
  state: () => ({
    list: [],
    keywords: '',
    dateTime: [
      moment()
        .month(moment().month())
        .startOf('month')
        .format('YYYY-MM-DD') || '',
      moment()
        .month(moment().month())
        .endOf('month')
        .format('YYYY-MM-DD') || ''
    ],
    pay_platform: '',
    platformList: [],
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    enterprise: {},
    enterpriseAmount: []
  }),
  mutations: {
    setList(state, data) {
      state.list = data.list;
      state.total = data.total * 1;
    },
    setPageSize(state, data) {
      state.pageSize = data;
    },
    setPageNumber(state, data) {
      state.pageNumber = data;
    },
    setKeywords(state, data) {
      state.keywords = data;
      state.pageNumber = 1;
      state.pageSize = 10;
    },
    setDateTime(state, data) {
      state.dateTime[0] = data[0];
      state.dateTime[1] = data[1];
      state.pageNumber = 1;
      state.pageSize = 10;
    },
    setPayPlatform(state, data) {
      state.pay_platform = data;
      state.pageNumber = 1;
      state.pageSize = 10;
    },
    setPlatformList(state, data) {
      state.platformList = data;
    },
    setClear(state) {
      state.dateTime = [
        moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD') || '',
        moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD') || ''
      ];
      state.pageNumber = 1;
      state.pageSize = 10;
      state.total = 0;
    },
    setEnterpruseAmount(state, data) {
      state.enterpriseAmount = data;
    },
    setEnterpriseStatistics(state, data) {
      state.enterprise = data;
    }
  },
  actions: {
    async updateList({ commit, state }) {
      const result = await statisticsEnterprisePayRecoreds({
        keywords: state.keywords,
        pay_platform: state.pay_platform,
        startTime: state.dateTime[0] + ' 00:00:00',
        endTime: state.dateTime[1] + ' 23:59:59',
        pageSize: state.pageSize,
        pageNumber: state.pageNumber,
        $ignoreRepeat: true
      });
      const list = [];
      for (let index = 0; index < result.list.length; index++) {
        const it = result.list[index];
        // 增值税
        it.addTax = math.round(
          math.evaluate('x/1.06*0.06', { x: it.total }),
          2
        );
        if (it.pay_platform === 'm1' || it.pay_platform === 'm2') {
          // 附加税
          it.surtax = math.round(
            math.evaluate('(x/1.06*0.06)*0.12', { x: it.total }),
            2
          );
          // 城建
          it.urbanTax = math.round(
            math.evaluate('(x/1.06*0.06)*0.07', { x: it.total }),
            2
          );
        }
        if (it.pay_platform === 'm3') {
          // 附加税
          it.surtax = math.round(
            math.evaluate('(x/1.06*0.06)*0.1', { x: it.total }),
            2
          );
          // 城建
          it.urbanTax = math.round(
            math.evaluate('(x/1.06*0.06)*0.05', { x: it.total }),
            2
          );
        }
        // 目前只有陕西添加水利基金
        if (it.pay_platform === 'm1') {
          // 水利基金
          it.waterFund = math.round(
            math.evaluate('x/1.06*0.0005', { x: it.total }),
            2
          );
        }
        if (it.pay_platform === 'm2' || it.pay_platform === 'm3') {
          it.waterFund = 0;
        }
        // 税款
        it.amountTax = it.addTax + it.surtax + it.waterFund;
        // 发放金额
        it.out = it.total - it.free;
        it.pureFree = it.total - it.out - it.amountTax;
        // 教育附加
        it.educationTax = math.round(
          math.evaluate('(x/1.06*0.06)*0.03', { x: it.total }),
          2
        );
        // 地方教育附加
        it.localEducationTax = math.round(
          math.evaluate('(x/1.06*0.06)*0.02', { x: it.total }),
          2
        );
        list.push(it);
      }
      result.list = list;
      commit('setList', result);
    },
    async getAmount({ commit, state }) {
      const result = await describeEnterpriseTotalAmount({
        keywords: state.keywords,
        pay_platform: state.pay_platform,
        startTime: state.dateTime[0] + ' 00:00:00',
        endTime: state.dateTime[1] + ' 23:59:59'
      });
      const list = [];
      if (result.pay_platform) {
        const it = result;
        // 增值税
        it.addTax = math.round(
          math.evaluate('x/1.06*0.06', { x: it.total }),
          2
        );
        if (it.pay_platform === 'm1' || it.pay_platform === 'm2') {
          // 附加税
          it.surtax = math.round(
            math.evaluate('(x/1.06*0.06)*0.12', { x: it.total }),
            2
          );
          // 城建
          it.urbanTax = math.round(
            math.evaluate('(x/1.06*0.06)*0.07', { x: it.total }),
            2
          );
        }
        if (it.pay_platform === 'm3') {
          // 附加税
          it.surtax = math.round(
            math.evaluate('(x/1.06*0.06)*0.1', { x: it.total }),
            2
          );
          // 城建
          it.urbanTax = math.round(
            math.evaluate('(x/1.06*0.06)*0.05', { x: it.total }),
            2
          );
        }
        // 目前只有陕西添加水利基金
        if (it.pay_platform === 'm1') {
          // 水利基金
          it.waterFund = math.round(
            math.evaluate('x/1.06*0.0005', { x: it.total }),
            2
          );
        }
        if (it.pay_platform === 'm2' || it.pay_platform === 'm3') {
          it.waterFund = 0;
        }
        // 发放金额
        it.out = it.total - it.free;
        // 教育附加
        it.educationTax = math.round(
          math.evaluate('(x/1.06*0.06)*0.03', { x: it.total }),
          2
        );
        // 地方教育附加
        it.localEducationTax = math.round(
          math.evaluate('(x/1.06*0.06)*0.02', { x: it.total }),
          2
        );
        // 税款
        it.amountTax = it.addTax + it.surtax + it.waterFund;
        it.pureFree = it.total - it.out - it.amountTax;
        list.push(it);
      }
      commit('setEnterpruseAmount', list);
    },
    async init({ dispatch, state }) {
      if (state.list.length === 0) {
        dispatch('updateList');
        dispatch('getAmount');
      }
    }
  }
};
