<template>
  <div class="env-label" v-show="VUE_APP_NODE_ENV != 'production'">
    当前环境：{{ VUE_APP_NODE_ENV | nameLable }}; 接口地址：{{
      VUE_APP_BASE_URL
    }};
  </div>
</template>
<script>
export default {
  name: 'EnvLabel',
  data() {
    return {
      VUE_APP_NODE_ENV: process.env.VUE_APP_NODE_ENV,
      VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL
    };
  },
  filters: {
    nameLable(value) {
      return (
        {
          test: '测试环境',
          development: '开发环境'
        }[value] || '未知'
      );
    }
  }
};
</script>
<style scoped>
.env-label {
  position: fixed;
  left: 1px;
  top: 1px;
  padding: 0px 5px;
  color: red;
  font-size: 12px;
  background: #fff;
  transform-origin: 0 0;
  transform: scale(0.8);
  z-index: 3000;
}
</style>
