import {
  api
} from './base/index';
const userApi = api('/user');
/**
 *发送注册验证码
 */
export const userSendRegistryCode = userApi('user.send.registry.code.json');
export const userSendLoginCode = userApi('user.send.login.code.json');
/**
 *用户注册
 */
export const userRegistry = userApi('user.registry.json');
/**
 *企业注册
 */
export const enterpriseRegistry = userApi('enterprise.registry.json');
/**
 *用户登录
 */
export const userLogin = userApi('user.login.json');
export const userLoginSms = userApi('user.login.sms.json');

/**
 *
 *编辑用户信息
 */
export const userEdit = userApi('user.edit.json');
/**
 *删除用户
 */
export const userDelete = userApi('user.delete.json');
/**
 *查询用户列表
 */
export const userList = userApi('user.list.json', 'allUserList');

/**
 *退出登录
 */
export const userLogout = userApi('user.logout.json');

/**
 *查询用户信息
 */
export const userDescribe = userApi('user.describe.json');

export const startAuth = userApi('start.auth.json');