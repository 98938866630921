export default [
  {
    path: 'customer/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '客户管理',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'customer/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '客户详情',
    }
  },
  {
    path: 'customer/add',
    component: resolve => require(['./add'], resolve),
    meta: {
      name: '添加客户',
    }
  },
  {
    path: 'customer/moneyDetail',
    component: resolve => require(['./moneyDetail'], resolve),
    meta: {
      name: '企业交易量统计详情',
    }
  }
]