export default [
  {
    path: 'contract',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '合同管理',
      isTag: true,
      icon: require('/src/assets/contract.svg'),
      icons: require('/src/assets/contract_show.svg')
    }
  },
  {
    path: 'contract/list',
    name: 'ContractList',
    component: resolve => require(['./List.vue'], resolve),
    meta: {
      name: '合同管理',
      isTag: true,
      // keepAlive:true,
    }
  },
  {
    path: 'contract/detail',
    name: 'ContractDetail',
    component: resolve => require(['./Detail.vue'], resolve)
  },
  {
    path: 'contract/add',
    name: 'ContractAdd',
    component: resolve => require(['./contract/Add.vue'], resolve)
  },
  {
    path: 'contract/contractTemplate',
    component: resolve => require(['./template/contractTemplate'], resolve),
    meta: {
      keepAlive:false,
    }
  },
  {
    path: 'contract/pdf',
    component: resolve => require(['./pdf.vue'], resolve)
  },
  {
    path: 'contractTemplate/detail',
    name: 'ContractTemplateDetail',
    component: resolve => require(['./template/Detail.vue'], resolve)
  },
  {
    path: 'contractTemplate/list',
    name: 'ContractTemplateList',
    component: resolve => require(['./template/List.vue'], resolve),
    meta: {
      name: '合同模板',
      isTag: true,
      keepAlive:true,
    }
  },

  {
    path: '/',
    component: resolve => require(['./template/contractTemplate'], resolve)
  }
];
