export default [
  {
    path: 'roleManagement/List',
    component: resolve => require(['./index'], resolve)
  },
  {
    path: 'roleManagement/authorization',
    component: resolve => require(['./authorization'], resolve)
  },
  {
    path: 'roleManagement/exportResources',
    component: resolve => require(['./exportResources'], resolve)
  }
];
