export default [
  {
    path: 'service/index',
    component: resolve => require(['./List'], resolve),
    meta: {
      name: '服务包列表',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'service/add',
    component: resolve => require(['./add'], resolve),
    meta: {
      name: '新建服务包',
    }
  },
  {
    path: 'service/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '服务包详情',
    }
  },
  {
    path: 'service/serviceTemplate',
    component: resolve => require(['./serviceTemplate'], resolve),
    meta: {
      name: '工资单模板',
    }
  },
  {
    path: 'service/configuration',
    name: 'configuration',
    component: resolve => require(['./configuration.vue'], resolve)
  },

  {
    path: 'service/addTemplate',
    component: resolve => require(['./addTemplate'], resolve),
    meta: {
      name: '新建工资单模板',
    }
  }
]