export default [
  {
    path: 'payroll/index',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '薪酬管理',
      isTag: true,
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg')
    }
  },
  {
    path: 'payroll/detail',
    component: resolve => require(['./detail'], resolve),
    meta: {
      name: '工资单详情',
    }
  },
  {
    path: 'payroll/organization',
    component: resolve => require(['./org'], resolve),
    meta: {
      name: '组织架构',
    }
  }
]