export default [
  {
    path: 'index/dashboard',
    component: resolve => require(['./dashboard'], resolve)
  },
  {
    path: 'index/home',
    component: resolve => require(['./home'], resolve),
    meta: {
      icon: require('/src/assets/home.svg'),
      icons: require('/src/assets/home-01.svg')
    }
  },
  {
    path: 'index/enterpriseHome',
    component: resolve => require(['./enterpriseHome'], resolve),
    meta: {
      icon: require('/src/assets/home.svg'),
      icons: require('/src/assets/home-01.svg')
    }
  },
  {
    path: 'index/agentHome',
    component: resolve => require(['./agentHome'], resolve),
    meta: {
      icon: require('/src/assets/home.svg'),
      icons: require('/src/assets/home-01.svg')
    }
  },
  {
    path: 'index/platformHome',
    component: resolve => require(['./platformHome'], resolve),
    meta: {
      icon: require('/src/assets/home.svg'),
      icons: require('/src/assets/home-01.svg')
    }
  }
];
