export default [
  {
    path: 'customer',
    component: resolve => require(['./index'], resolve),
    meta: {
      name: '用户管理',
      isTag: true,
      icon: require('/src/assets/user.svg'),
      icons: require('/src/assets/user_show.svg')
    }
  },
  {
    path: 'user/owner-info',
    component: resolve => require(['./DetailUserInfo'], resolve),
    meta: {
      name: '用户管理',
      isTag: true,
      icon: require('/src/assets/user.svg'),
      icons: require('/src/assets/user_show.svg')
    }
  },
  {
    path: 'user/detail',
    component: resolve => require(['./DetailUserInfo'], resolve),
    meta: {
      name: '当前用户',
      isTag: true,
      icon: require('/src/assets/user01.svg'),
      icons: require('/src/assets/user01_show.svg')
    }
  },
  {
    path: 'user/personnelComparison',
    component: resolve => require(['./personnelComparison'], resolve),

    meta: {
      name: '创客信息对比',
      isTag: true,
      icon: require('/src/assets/user07.svg'),
      icons: require('/src/assets/user07_show.svg')
    }
  },
  {
    path: 'user/erleihu',
    component: resolve => require(['./erleihu'], resolve),

    meta: {
      name: '二类户列表',
      isTag: true,
      icon: require('/src/assets/user06.svg'),
      icons: require('/src/assets/user06_show.svg')
    }
  },
  {
    path: 'user/list',
    component: resolve => require(['./List'], resolve),
    meta: {
      name: '合作创客',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/user04.svg'),
      icons: require('/src/assets/user04_show.svg')
    }
  },
  {
    path: 'user/customerlist',
    component: resolve => require(['./customerUserList'], resolve),
    meta: {
      name: '企业用户',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/user04.svg'),
      icons: require('/src/assets/user04_show.svg')
    }
  },
  {
    path: 'user/bmdList',
    component: resolve => require(['./bmdList'], resolve)
  },
  {
    path: '/',
    component: resolve => require(['./List'], resolve),
    meta: {
      // keepAlive:true,
    }
  }
];
