export default [
  {
    path: 'enterprise/list',
    name: 'EnterpriseList',
    component: () => import('./List.vue'),
    meta: {
      name: '企业客户',
      isTag: true,
      // keepAlive:true,
      icon: require('/src/assets/user05.svg'),
      icons: require('/src/assets/user05_show.svg')
    }
  },
  {
    path: 'enterprise/detail',
    name: 'EnterpriseDetail',
    component: () => import('./enterprise_info/Detail.vue'),
    meta: {
      name: '当前企业',
      isTag: true,
      icon: require('/src/assets/user02.svg'),
      icons: require('/src/assets/user02_show.svg')
    }
  },
  {
    path: 'enterprise/edit',
    name: 'EnterpriseDetail',
    component: () => import('./enterprise_info/editNew.vue')
  },
  {
    path: '/',
    component: resolve => require(['./List.vue'], resolve)
  }
];
